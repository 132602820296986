@import '../../../../../../styles/variables.scss';

.container {
    height: auto;
    width: 574px;
    border-radius: 40px;
    background-color: $whiteGray;
    overflow: hidden;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 305px;
    background-color: $white;
}

.content {
    padding: 26px 36px 32px;
}

.description {
    margin-bottom: 24px;
    font-size: 18px;
    font-family: 'Open Sans';
    font-weight: 800;
    line-height: 24px;
}

.stackList {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.stackListItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $lightGray;
    background-color: $white;
}

.stackListItemIcon {
    width: 24px;
    height: 24px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stackListItemName {
    margin: 0;
    color: rgba(0, 0, 0, 0.90);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.62;
}

@media all and (max-width: 767px) {
    .container {
        height: auto;
        width: 100%;
        border-radius: 32px;
    }

    .logo {
        height: 160px;
    }

    .container:not(:last-child) {
        margin-bottom: 16px;
    }
}