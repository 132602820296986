@import '../../../../../../styles/variables.scss';

.container {
    padding: 40px 40px 48px;
    background-color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 576px;
    border-radius: 40px;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: $black;
    font-family: Open Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.18;
    margin-top: 46px;
    margin-bottom: 16px;
}

.subtitle {
    color: $black;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    margin: 0;
}

.button {
    width: 300px;
    height: 56px;
    background-color: $lightBlue300;
    border-radius: 16px;
    border: none;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3;
    color: $black;
    margin-top: 32px;
    transition: .3s ease;
}

.button:hover {
    background-color: $hoverBlue;
}

@media all and (max-width: 767px) {
    .container {
        padding: 49px 24px 24px;
        width: $mobilePageWidth;
        border-radius: 32px;

        svg {
            width: 150px;
            height: 139px;
        }
    }
    
    .title {
        font-size: 20px;
        line-height: 0.8;
    }
    
    .subtitle {
        font-size: 16px;
        line-height: 1.5;
    }
    
    .button {
        width: 295px;
        font-size: 16px;
        line-height: 1.5;
        margin-top: 24px;
    }
}