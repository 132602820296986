@import '../../styles/variables.scss';

.sectionLabel {
    font-family: Open Sans;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: fit-content;
    padding: 0 8px;
    background-color: rgba(135, 191, 255, 0.50);
}

.dark {
    color: $anotherBlack;
}

.light {
    color: $white;
}