$black: #0F0B16;
$anotherBlack: #101010;
$grey: #423D4D;
$middleGray: #746E80;
$lightGray: #ECEAEF;
$whiteGray: #EDF0F5;
$white: #ffffff;
$darkBlue: #3423B2;
$blue: #225DE5;
$lightBlue300: #ADD7F6;
$hoverBlue: #8AC8F6;
$blueText: #2667FF;

$desktopPageWidth: 1180px;
$mobilePageWidth: calc(100% - 2*16px);