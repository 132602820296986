@import '../../../../../../styles/variables.scss';

.serviceCard {
    width: 678px;
    background-color: $white;
    border-radius: 40px;
    padding: 40px 44px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.title {
    color: $black;
    font-family: Open Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.18;
    margin: 0;
}

.taskList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    padding-left: 23px;
}

.taskListItem {
    color: $grey;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
}

.stackTitle {
    color: $black;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.9;
    margin: 0;
}

.stackList {
    padding: 0;
    margin: 8px 0 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.stackListItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $lightGray;
}

.stackListItemIcon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stackListItemName {
    margin: 0;
    color: rgba(0, 0, 0, 0.90);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.62;
}

@media all and (max-width: 767px) {
    .serviceCard {
        width: 100%;
        border-radius: 32px;
        padding: 20px 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .title {
        font-size: 24px;
        line-height: 1.25;
    }
    .taskListItem {
        font-size: 16px;
        line-height: 1.5;
    }
    .stackTitle {
        font-size: 18px;
        line-height: 1.5;
    }
}