@import '../../../../styles/variables.scss';

.container {
    width: 100vw;
}

.heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 16px;

    & > a > svg:first-child {
        width: 73px;
        height: 28px;
    }
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    padding: 0;
}

.anchors {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 1.5;
    color: $black;
    padding: 24px;
}

.subanchors {
    display: flex;
    flex-direction: column;
}

.listItem {
    font-weight: 700;
    padding: 8px 0;
    cursor: pointer;
}

.sublistItem {
    font-weight: 400;
    padding: 8px 0;
    cursor: pointer;
}