@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    src: url('../assets/fonts/Inter/Inter-Regular.ttf') format('ttf'),
        url('../assets/fonts/Inter/Inter-Regular.woff2') format("woff2"),
        url('../assets/fonts/Inter/Inter-Regular.woff') format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 500;
    src: url('../assets/fonts/Inter/Inter-Medium.ttf') format('ttf'),
        url('../assets/fonts/Inter/Inter-Medium.woff2') format('woff2'),
        url('../assets/fonts/Inter/Inter-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    src: url('../assets/fonts/Inter/Inter-SemiBold.ttf') format('ttf'),
        url('../assets/fonts/Inter/Inter-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/Inter/Inter-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style:  normal;
    font-weight: 800;
    src: url('../assets/fonts/OpenSans/OpenSans-ExtraBold.ttf') format('ttf'),
        url('../assets/fonts/OpenSans/OpenSans-ExtraBold.woff2') format("woff2"),
        url('../assets/fonts/OpenSans/OpenSans-ExtraBold.woff') format("woff");
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../assets/fonts/OpenSans/OpenSans-Italic.ttf') format('ttf'),
        url('../assets/fonts/OpenSans/OpenSans-Italic.woff2') format("woff2"),
        url('../assets/fonts/OpenSans/OpenSans-Italic.woff') format("woff");
}


